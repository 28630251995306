import React from "react"
import Image from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import { graphql } from "gatsby"

import AboutPage from "../components/aboutPage"
import Projects from "../components/projects"

const About = ({ data, location }) => {
  return (
    <Layout location={location} title="Home">
      <SEO title={"About – baran.live"} />
      {/* <Image
        fixed={data.avatar.childImageSharp.fixed}
        alt={"picture of baran"}
        style={{
          marginRight: rhythm(1 / 2),
          marginBottom: 0,
          minWidth: 50,
          borderRadius: `100%`,
          userSelect: `none`,
        }}
        imgStyle={{
          borderRadius: `50%`,
        }}
      /> */}
      {/* <h1>Coming soon. <span role={"img"} aria-label={"construction sign"}>🚧</span></h1> */}
      <AboutPage></AboutPage>
      <Projects></Projects>

    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query {
    avatar: file(absolutePath: { regex: "/baran_colour_square.png/" }) {
      childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
