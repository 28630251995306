import React from "react"

const about = () => {
  return (
    <div>
      <p>Baran is a 21 year old Computer Science student. He is currently studying at Coventry University, where he's on track to graduate with a First-Class in his final year.</p>
    </div>
  )
}

export default about
